import React from "react";
import {
    GoogleMap,
    withScriptjs,
    withGoogleMap,
    Marker 
} from "react-google-maps";
import mapStyles from "../components/mapStyles";

const Map = ()=>{
    return (
        <GoogleMap 
            defaultZoom={18}
            defaultCenter={{lat:4.7063917, lng: -74.2004592}}
            defaultOptions={{styles:mapStyles,fullscreenControl: false}}
        >
            <Marker position={{lat:4.7063917, lng: -74.2004592}}
            title="IDEA SAS"
            />
            </GoogleMap>
    );
};

export default withScriptjs(
    withGoogleMap(
        Map
    )
)