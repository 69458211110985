import React, {useState,useEffect} from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF,faInstagram,faWhatsapp,faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import Map from "../components/map"
import SEO from "../components/seo"
const Contacto = (props) => {
  const [alto,setalto] = useState(0)
  useEffect(() => {
    const vw = window.innerWidth;
    const vh = window.innerHeight;
    if(vw<=414){
      setalto(vh*0.4)
    }else{
      setalto(vh*0.2)
    }
  }, [])
  return(
<> 
  <SEO title="Contacto" />
  <div id="contacto">
    <div className="mainContacto">
      <div className="informacion">
        <h1 className="contacto">Contacto</h1>

        <h1>Correo electrónico:</h1>
        <p>Gerencia@ideautomation.com.co</p>
        <h1>Teléfonos:</h1>
        <p>Dpto Sistemas de pesaje: 3057666868</p>
        <p>Dpto Diseño y ergonomia industrial: 3108148027</p>
        <p>Dpto Mantenimiento y energias renovables: 3134504267</p>
        <h1>Dirección:</h1>
        <p>Carrera 2 #20-36 Funza Cundinamarca</p>
        <h1>Redes:</h1>
        <a href="#"><FontAwesomeIcon icon={faFacebookF} /></a>
        <a href="#"><FontAwesomeIcon icon={faInstagram} /></a>
        <a href="#"><FontAwesomeIcon icon={faLinkedinIn} /></a>
        <a href="https://web.whatsapp.com/send?phone=573057666868"><FontAwesomeIcon icon={faWhatsapp} /></a>
      </div>
      <img className="imagenC" src="../automatizacionP.jpg" alt=""/>
    </div>
    <div className="mapa">
        <Map googleMapURL= {"https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyAr56-0s8UbtTUlMrQgHK9DQXmDjcwg4Ss"}
            containerElement= {<div style={{height:alto,width:"100%"}}></div>}
            mapElement = {<div style={{height:alto,width:"100%"}}></div>}
            loadingElement = {<p>Cargando</p>}
        />
      </div>
  </div>


</>
)}

export default Contacto
